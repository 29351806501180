.playcard {
  max-width: 250px;
  flex: 1;
  height: 150px;
  position: relative;
  &__wrapper {
    max-width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
    .livetag {
      left: 8px;
      top: 8px;
      background: #ff2525;
      position: absolute;
      font-weight: 500;
      font-size: 14px;
      line-height: 30px;
      color: #ffffff;
      padding: 0px 10px;
      text-transform: capitalize;
    }
  }
  &__titleimg {
    width: 100%;
    height: 150px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include border-radius(15px);
    }
    @media screen and (max-width: 520px) {
      height: 130px;
    }
  }
  &__logo {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
    img {
      width: 35px;
      height: 35px;
      object-fit: contain;
    }
  }
  &__view {
    background: #fff;
    overflow: hidden;
    .vjs-poster {
      object-fit: cover !important;
      background-size: cover !important;
    }
    @include border-radius(20px);
    .playcard__titleimg {
      position: relative;
      cursor: pointer;
      video {
        object-fit: cover;
        @include border-radius(20px 20px 0 0);
      }
    }
    &-desc {
      padding: 12px;
      h6 {
        font-size: 16px;
        line-height: 22px;
        color: #000000;
        margin-bottom: 6px;
        margin-top: 6px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-weight: 700;
      }
      p {
        font-size: 12px;
        line-height: 16px;
        color: #000000;
        margin-bottom: 8px;
        display: -webkit-box;
        max-width: 200px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .tags-wrapper {
        display: flex;
        span {
          font-size: 12px;
          line-height: 16px;
          color: #000000;
          display: flex;
          position: relative;
          padding: 0 15px;
          display: flex;
          align-items: center;

          &::before {
            content: "";
            width: 8px;
            height: 8px;
            @include theme(background-color, themeColor);
            border-radius: 100%;
            position: absolute;
            left: 0;
          }
        }
      }
    }
    .btn-wrapper {
      display: flex;
      .btn-icon {
        margin-right: 15px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  &.playhover {
    max-width: 100%;
    .playcard__view {
      background: white;
      position: absolute;
      top: -25%;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease-in-out;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
      z-index: 1;
      width: 100%;
    }
    &:hover {
      .playcard__view {
        visibility: visible;
        transform: scale(1.1);
        z-index: 1;
        opacity: 1;
      }
    }
  }
  // media
  @media screen and (max-width: 991px) {
    height: auto;
    &__wrapper {
      display: none;
    }
    &__view {
      position: relative !important;
      top: 0 !important;
      visibility: visible !important;
      opacity: 1 !important;
      left: 0px !important;
      &-desc {
        p {
          -webkit-line-clamp: 1;
        }
      }
    }
    &:hover .playcard__view {
      transform: scale(1) !important;
    }
  }
}

.tags-wrapper {
  display: flex;
  span {
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    display: flex;
    position: relative;
    padding: 0 15px;
    display: flex;
    align-items: center;

    &::before {
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 100%;
      position: absolute;
      left: 0;
    }
  }
}

.love-icon {
  svg {
    animation: liked 0.4s ease;
  }
  &.active {
    svg {
      will-change: width;
      animation: heart 1s cubic-bezier(0.17, 0.89, 0.32, 1.49);
    }
  }
}
@keyframes heart {
  0%,
  15% {
    width: 0;
  }
}
@keyframes liked {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.btn-wrapper {
  .active {
    @include theme(background-color, themeColor);
    svg {
      path {
        fill: #fff !important;
        stroke: #fff !important;
      }
    }
  }
}
.container {
  max-width: 100%;
  width: 100%;
  padding: 5px;
  position: relative;
  @media screen and (max-width: 991px) {
    &.m-pad0 {
      padding: 0;
    }
  }
}
.common {
  &__slider {
    padding-left: 5px;

    &:hover {
      .slick-list {
        z-index: 1;
      }
    }

    .slick-list {
      overflow: initial !important;
    }

    .slick-dots {
      display: block;
      top: -54px;
      left: 0;
      right: auto;
      bottom: auto;
      text-align: right;
      max-width: 1280px;
      margin: auto;
      transition: all 0.3s;

      li {
        width: 30px;
        display: inline-flex;
        align-items: center;
        transition: all 0.3s;

        button {
          width: 28px;
          display: flex;
          align-items: center;
          transition: all 0.3s;

          &::before {
            content: "";
            width: 25px;
            height: 5px;
            @include theme(background-color, buttonprimary);
            top: 8px;
            transition: all 0.3s;
          }
        }
      }
    }

    .slick-track {
      display: flex;
      margin: 0 -12px !important;
    }

    .slick-prev,
    .slick-next {
      @include theme(background-color, themeColor);
      width: 75px;
      height: 150px;
      z-index: 2 !important;

      &.slick-disabled {
        display: none !important;
      }
    }

    .slick-prev:before,
    .slick-next:before {
      content: "";
      width: 14px;
      height: 20px;
      background: url("../../img/right-arrow.svg");
      position: absolute;
      background-size: contain;
    }

    .slick-prev:before {
      top: 65px;
    }

    .slick-next:before {
      top: 40%;
    }

    .slick-prev {
      left: -90px;
      transform: translate(0, -50%) rotate(180deg);
    }

    .slick-next {
      right: -9px;
    }

    .slick-slide {
      padding: 0 12px !important;
    }

    @media screen and (min-width: 1720px) {
      .slick-dots {
        left: 8%;
        max-width: 1680px;
      }
    }

    @media screen and (max-width: 991px) {
      .slick-dots {
        text-align: center;
        top: auto;
        bottom: -32px;

        li {
          margin: 0;
          width: 25px;

          &:first-child {
            button {
              &::before {
                border-radius: 4px 0 0 4px;
              }
            }
          }

          &:last-child {
            button {
              &::before {
                border-radius: 0 4px 4px 0;
              }
            }
          }

          button {
            width: 25px;

            &::before {
              height: 3px;
            }
          }
        }
      }

      .slick-prev {
        left: -25px;
      }

      .slick-prev:before,
      .slick-next:before {
        height: 19px;
      }
    }

    @media screen and (max-width: 520px) {
      .slick-prev,
      .slick-next {
        height: 130px;
      }
    }
  }
  &__section {
    margin-top: 30px;
    margin-bottom: 30px;
    &:first-child {
      padding-top: 48px;
    }
  }
  &__container {
    overflow: hidden;
  }
  &-heading {
    margin-bottom: 24px;

    h3 {
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      color: #000000;

      span {
        margin-left: 5px;
        @include theme(color, textColor);
      }
    }

    &.white {
      h3 {
        color: #fff;
      }
    }

    @media screen and (max-width: 991px) {
      margin-bottom: 5px;

      h3 {
        font-size: 18px;
        display: inline-flex;
      }
    }
  }
  @media screen and (max-width: 991px) {
    &__section {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
}

// video
.video-player {
  width: 100%;
  height: 100%;

  > div {
    width: 100%;
    height: 100%;
  }

  .mute {
    img {
      width: 30px;
    }
  }

  .video-js,
  .vjs-playing {
    width: 100%;
    height: 100%;

    video {
      object-fit: cover;
    }
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin: auto;
  }

  .bannerCard__mute {
    position: absolute;
    bottom: 5%;
    right: 4%;
    z-index: 2;
  }
}

.btn-share-content {
  background: white;
  position: absolute;
  left: auto;
  right: 0;
  z-index: 3;
  width: auto;
  padding: 10px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  border-radius: 20px 0 20px 0;
  transition: all 0.2s ease;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.16);
  opacity: 0;
  visibility: hidden;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  button {
    margin: 5px 0;
    display: flex;
  }

  &.icons-share {
    flex-direction: row;
    height: 52px;
    top: -60px !important;
    border-radius: 20px;
    right: 0px !important;

    button {
      margin: 0px 5px;
      align-items: center;
    }

    @media screen and (max-width: 520px) {
      flex-direction: row;
      border-radius: 20px;
      height: 45px;
      top: -50px !important;
      right: 5px !important;
      padding: 5px 10px !important;

      button {
        margin: 2px 5px;
      }
    }
  }
}

.relatedvideos {
  .slick-active {
    &.slick-current {
      position: relative;
      z-index: 999999;

      .playcard.playhover .playcard__view,
      .playcard.playhover:hover .playcard__view {
        left: 16px !important;
        @media screen and (max-width: 991px) {
          left: 0px !important;
        }
      }
    }
  }

  .slick-active {
    .playcard {
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) !important;
    }
  }

  .common__slider .slick-arrow {
    @include theme(background-color, themeColor);
    width: 40px;
    height: 40px;
    z-index: 0 !important;
    border-radius: 100% !important;
  }

  .common__slider .slick-next {
    top: -42px !important;
    right: 24px !important;
  }

  .common__slider .slick-prev {
    left: auto !important;
    top: -42px !important;
    right: 80px !important;
  }

  .common__slider .slick-prev:before {
    top: 22% !important;
    left: 14px !important;
  }

  .common__slider .slick-next:before {
    top: 26% !important;
    left: 14px !important;
  }

  @media screen and (max-width: 991px) {
    .common__slider .slick-prev {
      left: auto !important;
      top: -32px !important;
      right: 80px !important;
    }

    .common__slider .slick-prev:before {
      top: 22% !important;
      left: 12px !important;
    }

    .common__slider .slick-arrow {
      @include theme(background-color, themeColor);
      width: 35px;
      height: 35px;
      z-index: 0 !important;
      border-radius: 100% !important;
    }

    .common__slider .slick-next {
      top: -31px !important;
      right: 24px !important;
    }

    .common__slider .slick-next:before {
      top: 26% !important;
      left: 12px !important;
    }
  }
}

.slick-slider {
  touch-action: auto;
  -ms-touch-action: auto;
}
