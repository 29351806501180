.videoplayer {
  &__wrapper {
    width: 100%;
    height: 100%;
    .videojs-wrap {
      width: 100%;
      height: 100%;
    }
    .video-js {
      width: 100%;
      height: 100%;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
    }
    .vjs-big-play-button {
      display: none !important;
    }
    .vjs-volume-panel,
    .vjs-fullscreen-control,
    .vjs-error-display,
    .vjs-loading-spinner {
      display: none;
    }
    .vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
      visibility: visible !important;
      opacity: 1 !important;
      transition-duration: 0s !important;
    }
    .vjs-picture-in-picture-control {
      display: block !important;
      z-index: 9;
      right: 140px !important;
      position: absolute;
      height: 30px;
      z-index: 99 !important;
      @media screen and (max-width: 991px) {
        right: auto !important;
        left: 90px !important;
      }
    }
    .vjs-poster {
      height: 100%;
      width: 100%;
      object-fit: cover !important;
      background-size: cover !important;
    }
    .video-js button {
      outline: none !important;
      box-shadow: none !important;
    }
    .vjs-live-control {
      position: absolute;
      left: 110px;
      top: 17px;
      font-size: 12px;
      background: #b1194b;
      height: 22px;
      padding: 3px;
      display: flex;
      text-align: center;
      width: 40px;
      justify-content: center;
      align-items: center;
      animation-duration: 500ms;
      animation-name: blink;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      @media screen and (max-width: 991px) {
        left: 10px;
        top: -13px;
        height: 17px;
        font-size: 10px;
        width: 30px;
      }
    }
    .vjs-control-bar {
      background: rgba(57, 57, 57, 0.33);
      border-radius: 10px;
      padding: 10px 15px;
      position: absolute;
      width: 625px;
      bottom: 25px;
      height: 55px;
      left: 45px;
      display: flex;
      align-items: center;
      @media screen and (max-width: 991px) {
        width: 100%;
        left: 0;
        background: transparent;
        bottom: 0;
        height: 40px !important;
        padding: 10px 15px 10px 5px;
      }
      @media screen and (min-width: 992px) and (max-width: 1165px) {
        width: 480px;
        left: 10px;
      }
    }
    .vjs-progress-control {
      max-width: 48%;
      margin-left: 45px;
      @media screen and (max-width: 991px) {
        max-width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        bottom: -4px;
        margin: 0;
        height: 10px;
        .vjs-progress-holder {
          margin: 0;
        }
        .vjs-play-progress:before {
          content: none;
        }
      }
    }
    .vjs-play-progress {
      background-color: #d81c5b;
    }
    .vjs-play-progress:before {
      color: #d81c5b;
    }
    .vjs-play-control[title="Play"] .vjs-icon-placeholder {
      &::before {
        content: url("../../img/playIcon.svg");
      }
    }
    .vjs-play-control[title="Pause"] .vjs-icon-placeholder {
      &::before {
        content: url("../../img/pauseIcon.svg");
      }
    }
    .vjs-play-control[title="Replay"] .vjs-icon-placeholder {
      &::before {
        content: url("../../img/refreshIcon.svg");
      }
    }
    .vjs-play-control {
      margin-top: 10px;
    }
    &.ended-session {
      padding: 40px;
      margin: auto;
      background: #000;
      .poster {
        height: 430px;
        margin: auto;
      }
      .ended-test {
        text-align: center;
        padding-top: 60px;
        span {
          font-size: 24px;
          color: #fff;
          margin: auto;
          text-align: center;
          line-height: 140%;
          b {
            padding-top: 10px;
            display: block;
          }
        }
      }
      @media screen and (max-width: 991px) {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .poster {
          height: 100px;
        }
        .ended-test {
          padding-top: 10px;
          span {
            font-size: 12px;
            line-height: 120%;
          }
        }
      }
    }
    @media screen and (max-width: 991px) {
      .vjs-play-control[title="Play"] .vjs-icon-placeholder,
      .vjs-play-control[title="Pause"] .vjs-icon-placeholder,
      .vjs-play-control[title="Replay"] .vjs-icon-placeholder {
        &::before {
          transform: scale(0.8);
        }
      }
      .vjs-play-control {
        margin-top: -4px;
      }
      .vjs-default-skin.vjs-has-started .vjs-control-bar {
        display: block !important;
        visibility: visible !important;
        opacity: 1 !important;
        bottom: -3.4em !important;
        background-color: rgba(7, 20, 30, 1) !important;
      }
      .vjs-remaining-time {
        display: block !important;
      }
      .btn-mute-unmute {
        margin-right: auto !important;
      }
      .btn-mute-unmute {
        width: 55px !important;
        margin-left: 0px;
        margin-right: auto !important;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width:991px) {
          width: 35px !important;
        }
      }
    }
    @media screen and (min-width: 992px) and (max-width: 1165px) {
      .vjs-progress-control {
        max-width: 33%;
        margin-left: 45px;
      }
      .vjs-picture-in-picture-control {
        right: 145px !important;
      }
    }

    &.portrait-mode {
      display: flex;
      .vjs-control-bar {
        width: 100%;
        left: 0;
        bottom: 0;
      }
      .videoplayer__controllbar.vjs-control-bar {
        width: calc(100% - 45px);
      }
      .vjs-progress-control {
        position: absolute;
        width: 99%;
        top: -10px;
        left: 0;
        height: 15px;
        max-width: 100%;
        margin-left: 0;
      }
      .video-js .vjs-progress-control .vjs-progress-holder {
        margin: 0px;
      }
      #hearContainer {
        left: -10px !important;
        svg {
          width: 20px;
        }
      }
      .btn-share {
        svg {
          width: 15px;
        }
      }
      .btn-mute-unmute {
        width: 35px !important;
        margin-left: 0px;
        margin-right: auto !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .video-js .vjs-time-control {
        position: absolute;
        right: 0;
        top: -8px;
        height: 25px;
      }
      @media screen and (max-width: 991px) {
        #hearContainer {
          left: 0px !important;
        }
        .vjs-control-bar {
          height: 40px !important;
        }
        .video-js .vjs-time-control {
          top: -22px;
          height: 16px;
        }
        .vjs-play-control {
          margin-top: -4px !important;
        }
      }
    }
    .videoplayer__controllbar .video-like,
    .videoplayer__controllbar .btn-share,
    .videoplayer__controllbar .btn-chat,
    .videoplayer__controllbar .btn-cart {
      margin-right: 22px !important;
    }
  }
  &__controllbar {
    &.vjs-control-bar {
      z-index: 2;
      width: calc(625px - 50px);
      margin-left: 50px;
      background: transparent;
      @media screen and (max-width: 991px) {
        width: calc(100% - 45px);
        left: 0;
        margin-left: auto;
      }
      @media screen and (min-width: 992px) and (max-width: 1165px) {
        width: calc(480px - 50px);
      }
    }
    // .btn-icon {
    //   width: 36px;
    //   height: 36px;
    //   border: 1px solid #fff;
    //   border-radius: 100%;
    //   display: inline-flex;
    //   align-items: center;
    //   justify-content: center;
    //   margin-right: 15px;
    // }
    button {
      margin-right: 20px;
      &:last-of-type {
        margin-right: 0 !important;
        width: 40px !important;
      }
    }
    .progressbar {
      width: 400px;
      margin-right: 20px;
    }
    .video-like {
      position: relative;
      width: 32px;
      height: 27px;
      z-index: 1;
      #hearContainer {
        width: 32px;
        height: 32px;
        position: absolute;
        left: 0;
        top: 0px;
        overflow: visible;
        img {
          display: none;
        }
        &.active {
          svg {
            display: none;
          }
          img {
            display: block;
          }
        }
        #bigHeart {
          position: absolute;
          width: 32px;
          height: 32px;
          left: 0px;
          bottom: 2px;
          cursor: pointer;
          object-fit: contain;
        }

        .hearts {
          transform: translate(-50%, -50%);
          position: absolute;
          left: 50%;
          top: 50%;
          opacity: 0;
        }

        .dots {
          width: 7px;
          height: 7px;
          position: absolute;
          left: 50%;
          top: 50%;
          background-color: red;
          border-radius: 50%;
        }
        @media screen and (max-width: 991px) {
          width: 24px;
          height: 26px;
          #bigHeart {
            width: 24px;
            height: 26px;
            left: 0px;
            bottom: -1px;
          }
        }
      }
    }
    .like-hidden {
      width: 0;
      height: 0;
      opacity: 0;
      visibility: hidden;
    }
    .video-like,
    .btn-share {
      @media screen and (min-width: 992px) and (max-width: 1165px) {
        margin-right: 20px;
      }
    }
    @media screen and (max-width: 991px) {
      .video-like,
      .btn-cart {
        width: 22px;
        svg {
          width: 20px;
        }
      }
      .btn-chat {
        padding: 0;
        width: 45px;
        height: 22px;
        background: #fff;
        color: #000;
        border-radius: 0;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
      }
      .btn-share {
        svg {
          width: 15px;
        }
      }
      button {
        img {
          width: 21px !important;
          margin-top: 2px;
        }
      }
    }
    @media screen and (min-width: 992px) and (max-width: 1165px) {
      .progressbar {
        width: 235px;
      }
    }
  }
  &__header {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 991px) {
      flex-direction: column;
      padding: 10px;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      margin: 20px 10px 10px;
      width: calc(100% - 20px);
    }
  }
  &__profile {
    display: flex;
  }
  &__profile-icon {
    width: 72px;
    height: 72px;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      object-fit: contain;
    }
    @media screen and (max-width: 991px) {
      width: 36px;
      height: 36px;
    }
  }
  &__profile-desc {
    width: calc(100% - 88px);
    h4 {
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
      color: #000000;
    }
    span {
      font-weight: 500;
      font-size: 10px;
      line-height: 14px;
      color: #1a1a1a;
    }
    @media screen and (max-width: 991px) {
      h4 {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
  &__profile-live {
    h4 {
      color: red;
    }
  }
  &__desc {
    width: 50%;
    h5 {
      font-weight: bold;
      font-size: 24px;
      line-height: 33px;
      @include theme(color, titleColor);
      margin-bottom: 10px;
    }
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      @include theme(color, descColor);
    }
    @media screen and (max-width: 991px) {
      width: 100%;
      padding-top: 5px;
      h5 {
        font-size: 18px;
        line-height: 130%;
        margin-bottom: 5px;
      }
      p {
        font-size: 12px;
      }
    }
  }
}
.fullscreen-video {
  &.template__wrapper {
    background: #e5e5e5;
    object-fit: contain;
    position: fixed !important;
    top: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    left: 0px !important;
    box-sizing: border-box !important;
    min-width: 0px !important;
    max-width: none !important;
    min-height: 0px !important;
    max-height: none !important;
    width: 100% !important;
    height: 100% !important;
    transform: none !important;
    margin: 0px !important;
    z-index: 9;
  }
  .videoplayer__wrapper {
    height: 100% !important;
  }

  .videoplayer__wrapper,
  .cta__wrapper {
    height: 100%;
  }
  .cta__detailwrap {
    padding-top: 15px;
    height: 100vh;
    padding-right: 10px;
  }
  .videoplayer__wrapper .video-js {
    border-radius: 0px;
  }
}
@media screen and (max-width: 991px) {
  .videoplayer__wrapper {
    height: 600px !important;
  }
  .videoplayer__wrapper .live__chat {
    visibility: hidden;
  }
  // .template__wrapper .cta__wrapper {
  //   display: none;
  // }
  .live-views {
    top: 15px !important;
    left: 15px !important;
  }
  .videoplayer__controllbar {
    width: 100%;
    bottom: 0;
    left: 0;
  }
  .vjs-control-bar {
    padding: 10px 15px 20px 5px;
    border-radius: 0px !important;
    background: rgba(0, 0, 0, 0.3) !important;
  }
  .videoplayer__controllbar {
    background: transparent !important;
  }
  .vjs-time-control {
    max-width: 100%;
    position: absolute !important;
    left: 0;
    right: 0px;
    width: 100% !important;
    bottom: -31px;
    text-align: right !important;
    padding-right: 15px !important;
  }
  .live__chat {
    overflow: visible !important;
    &.active {
      display: block;
      visibility: visible;
      &.is_minimized {
        visibility: hidden !important;
        opacity: 0 !important;
        width: 0 !important;
        height: 0 !important;
      }
    }
    &.inactive {
      display: none !important;
    }
  }
  // full screen mode
  .fullscreen-video {
    flex-direction: row-reverse;
    &.template__wrapper {
      background: #8b8b8b;
    }
    .cta__wrapper {
      height: 100vh;
      width: 250px;
      min-width: 250px;
      padding-top: 60px;
      display: block;
    }
    .cta__detailwrap {
      height: 100%;
      padding-top: 0px;
    }
    .live-views {
      position: fixed;
      top: 16px;
      left: 40px;
    }
    .live__chat {
      max-width: 245px;
      min-width: 245px;
      right: auto;
      left: -245px;
      bottom: 53px;
      top: auto;
      z-index: 2;
      border-radius: 0px;
      height: calc(100vh - 135px);
      background: transparent;
      h5 {
        display: none;
      }
    }
    .live__chat__body .chat-message {
      margin-bottom: 5px;
    }
    .live__chat__header {
      background: transparent !important;
    }
    .live__chat__footer {
      background: #777777;
      border-radius: 10px;
      border: 0px;
      position: fixed;
      bottom: 7px;
      width: 240px;
      height: 40px;
    }
    .live__chat__body {
      height: 98%;
    }
    .videoplayer__controllbar .progressbar {
      max-width: 23%;
      width: 100%;
    }
    .video-js {
      box-shadow: none;
    }

    @media screen and (orientation: portrait) {
      .live__chat {
        max-width: 100%;
        min-width: 100%;
        right: auto;
        left: 0;
        bottom: 60px;
        top: auto;
        z-index: 2;
        border-radius: 0px;
        height: 40vh;
        display: block !important;
        transition: all 0.5s ease-in-out !important;
        h5 {
          display: none;
        }
        &.inactive {
          visibility: hidden;
          opacity: 0;
          width: 0;
          height: 0;
        }
        &.active {
          &.is_minimized {
            width: auto;
          }
        }
      }
      .live__chat__body {
        height: calc(100% - 70px);
      }
      .live__chat__footer {
        border-radius: 0;
        position: relative;
        bottom: 0px;
        width: 100%;
        height: 45px;
        border-top: 1px solid;
      }
      .live__chat {
        background: transparent;
      }
    }
  }
  @media screen and (orientation: landscape) {
    .videoplayer__wrapper {
      height: 90vh !important;
    }
  }
}
.btn-chat {
  width: 38px;
  height: 38px;
  border-radius: 100%;
  box-sizing: border-box;
  padding: 6px;
  position: relative;
  span {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: #ffffff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 6px;
    line-height: 8px;
    @include theme(background-color, textColor);
    position: absolute;
    top: 2px;
    right: 2px;
  }
}

@keyframes blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
