@import './functions';

@mixin theme($property, $key, $inlineExtra:null, $postExtra:null) {
    @each $theme in $themes {
      @if (themeExists($key, $theme)) {
        $value: getThemeValue($key, $theme);
        @at-root .#{$theme} #{&} {
          #{$property}: #{$inlineExtra} #{$value} #{$postExtra};
          #{$property}: #{$inlineExtra} var(--theme-#{$theme}-#{$key}, #{$value}) #{$postExtra};
        }
      } @else if $theme != 'default' and $theme != 'light' and $theme != 'dark' { 
        @error "Property #{$key} doesn't exist in #{$theme}";
      }
    }
  }

  @mixin generateThemeMappings($themeName, $newThemeMap) {
    $themes: append($themes, $themeName, $separator: comma) !global;
  
    @each $key, $value in $newThemeMap {
      @if not map-has-key($themeValues, $key) {
        $themeValues: map-merge($themeValues, ($key: ( $themeName: $value ))) !global;
      }
      @else {
        $existingKeyMap: map-get($themeValues, $key);
        @if not map-get($existingKeyMap, $themeName) {
          $newKeyMap: map-merge($existingKeyMap, ( $themeName: $value ));
          $themeValues: map-merge($themeValues, ( $key: $newKeyMap )) !global;
        }
      }
    }
  }
  @mixin generateThemeVariables {
    @each $key, $val in $themeValues {
      @each $theme in $themes {
        @if (themeExists($key, $theme)) {
          --theme-#{$theme}-#{$key}: #{getThemeValue($key, $theme)};
        }
      }
    }
  }
  