.live {
  &__chat {
    position: absolute;
    right: 16px;
    top: auto;
    bottom: 30px;
    background: rgba(0, 0, 0, 0.6);
    width: 300px;
    height: 85%;
    transition: all 0.5s ease-in-out;
    overflow: hidden;
    z-index: 1;
    &.isclose {
      height: 45px;
      width: 140px;
      top: auto;
      .live__chat__header {
        border-bottom: 0;
        span {
          visibility: visible;
        }
        svg {
          transform: rotate(180deg);
        }
      }
      .live__chat__body,
      .live__chat__footer {
        opacity: 0;
        // height: 0px;
        transition: all 0.5s ease-in-out;
      }
      .live__chat__header {
        h5 {
          width: 100%;
        }
      }
      .pin-chat {
        h5 {
          font-size: 16px;
          @media screen and (max-width: 991px) {
            font-size: 12px;
          }
        }
      }
      @media screen and (max-width: 991px) {
        height: calc(100vh - 135px) !important;
        top: auto !important;
        bottom: 53px !important;
        .live__chat__body,
        .live__chat__footer {
          opacity: 1;
        }
      }
    }
    .chat-disabled {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.18);
      top: 0;
      font-size: 18px;
      color: #7b7b7b;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 50px;
      text-align: center;
      line-height: 140%;
    }
    &.portrait-mode {
      .live__chat__header h5 {
        width: 100%;
      }
      .pinchat-live {
        font-size: 15px;
        line-height: 140%;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        @media screen and (max-width: 991px) {
          font-size: 12px;
        }
      }
    }
    @media screen and (min-width: 991px) {
      &.portrait-mode {
        position: relative;
        width: 100%;
        left: auto;
        right: auto;
        bottom: auto;
        top: auto;
        .live__chat__header {
          background: transparent;
        }
      }
    }

    &__header {
      padding: 5px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      cursor: pointer;
      h5 {
        font-weight: 500;
        font-size: 17px;
        line-height: 140%;
        color: #ffffff;
        display: inline-flex;
        align-items: center;
        word-break: break-word;
        width: calc(100% - 35px);
      }
      &.pin-chat {
        h5 {
          font-size: 14px;
        }
        @media screen and (max-width: 991px) {
          display: flex !important;
          flex-direction: column-reverse !important;
          padding: 5px;
          background: rgba(0, 0, 0, 0.62) !important;
          width: 100%;
          margin: auto !important;
          text-align: center;
          h5 {
            display: block;
            font-size: 12px;
            text-align: center;
          }
          span {
            display: none !important;
          }
          svg {
            margin-top: 2px;
            margin-bottom: 2px;
          }
          @media screen and (orientation: landscape) {
            span,
            svg {
              display: none !important;
            }
          }
        }
      }

      img,
      svg {
        cursor: pointer;
        transition: all 0.2s ease-in-out;
      }

      span {
        visibility: hidden;
        margin-left: 10px;
      }
      @media screen and (max-width: 991px) {
        display: none;
      }
    }

    &__body {
      height: calc(100% - 106px);
      overflow-y: auto;
      overflow-x: hidden;
      padding: 15px 20px 0px 20px;
      margin-bottom: 10px;

      &::-webkit-scrollbar {
        width: 1px;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
        width: 1px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: rgb(192, 190, 190);
        border-radius: 20px;
        width: 1px;
      }
      .chat-message {
        display: block;
        margin-bottom: 10px;
        position: relative;

        .chat-avatar {
          margin-right: 8px;
          position: relative;
          flex: 0 0 auto;
          background: #f2f4fa;
          min-width: 50px;
          height: 24px;
          padding: 2px 5px;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: #233ea4;
          display: inline-block;
        }

        h4 {
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          color: #fff;
          margin-bottom: 0;
          padding-top: 3px;
          display: inline;
          word-break: break-word;
        }

        &-msg {
          display: block;
          position: relative;
        }

        &.sent {
          text-align: right;
          .chat-avatar {
            text-align: left;
            color: #fff;
            background: rgba(77, 255, 105, 0.3);
          }
        }
        &.admin-msg {
          .chat-message-msg {
            padding: 5px 10px;
            display: inline-block;
            background: rgba(220, 24, 100, 0.3) !important;
            span {
              color: #55152b !important;
            }
          }
        }
        @media screen and (max-width: 991px) {
          .chat-avatar {
            background: #adadad;
          }
        }
      }
    }

    &__footer {
      border-top: 1px solid rgba(255, 255, 255, 0.3);
      display: flex;
      position: relative;
      form {
        width: 100%;
      }
      input {
        background: transparent;
        width: calc(100% - 50px);
        resize: none;
        height: 43px;
        padding: 14px 0px 9px 20px;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: #ffffff;
        border: none;
        border-right: none;

        &::placeholder {
          color: #fff;
        }

        &:focus {
          outline: none;
        }
      }

      button {
        position: absolute;
        top: 12px;
        right: 14px;
      }
    }

    @media screen and (max-width: 991px) {
      top: auto;
      margin: 0px;
      bottom: 65px;
      left: auto;
      right: 0px;
      width: 100%;
      height: 180px;
      z-index: 9;

      &.isclose {
        bottom: 35px;
        top: 0;
        height: 24px;
      }

      &__header {
        padding: 0px 10px;

        h5 {
          font-size: 12px;
        }
      }

      &__body {
        height: 115px;
        padding-left: 10px;
        padding-top: 5px;
        margin-bottom: 0;
        .chat-message {
          .chat-avatar {
            span {
              font-size: 12px !important;
            }
          }

          h4 {
            font-size: 12px !important;
            word-break: break-word;
          }

          &.sent {
            .chat-message-msg {
              margin: 3px 0;
            }
          }
          @media screen and (max-width: 991px) {
            .chat-avatar {
              span {
                color: #444 !important;
              }
            }
          }
        }
      }

      &__footer {
        form {
          width: 100%;
          display: flex;
          align-items: center;
        }
        input {
          height: 35px;
          padding: 8px 10px 4px;
          font-size: 12px;
        }

        ::placeholder {
          font-size: 12px;
        }

        button {
          top: 8px;
          right: 8px;
        }
        svg {
          width: 18px;
        }
      }
    }
  }
}
