.btn {
  min-width: 86px;
  min-height: 20px;
  box-sizing: border-box;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  font-size: 16px;
  line-height: 24px;
  border-radius: 6px;
  &.active.focus,
  &.active:focus,
  &.focus,
  &:active.focus,
  &:active:focus,
  &:focus {
    outline: 0;
    box-shadow: none;
  }
  img {
    margin-right: 6px;
  }
  &:hover {
    box-shadow: 0px 8px 24px 0px #1214581f;
  }
  &.disabled {
    opacity: 0.3 !important;
    cursor: default !important;
  }
  &__primary {
    @include theme(background-color, buttonprimary);
    @include theme(color, buttoncolor);
  }
  &__secondry {
    @include theme(background-color, buttonsecondry);
    @include theme(color, buttoncolor);
  }
  &-icon {
    width: 30px;
    height: 30px;
    min-width: 30px !important;
    border-radius: 100%;
    padding: 0;
    border: 2px solid;
    @include theme(border-color, themeColor);
    @include theme(color, themeColor);
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    img {
      margin-right: 0 !important;
    }
    svg {
      &.fill {
        path {
          @include theme(fill, themeColor);
        }
      }
      &.stroke {
        path {
          @include theme(stroke, themeColor);
        }
      }
    }
    &:hover {
      @include theme(background-color, themeColor);
      color: #fff;
      svg {
        &.fill {
          path {
            fill: white;
          }
        }
        &.stroke {
          path {
            stroke: white;
          }
        }
      }
    }
  }
  &.disabled {
    opacity: 0.2;
    cursor: not-allowed;
    pointer-events: none;
    &:hover {
      background: inherit !important;
      svg {
        &.stroke {
          path {
            @include theme(stroke, themeColor);
          }
        }
      }
    }
  }
}
