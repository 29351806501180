.template {
  &__container {
    padding: 15px;
    background: #f1f1f1;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
  }
  &__sdk {
    position: relative;
    @include flexbox();
    width: 100% !important;
  }
  &__wrapper {
    position: relative;
    @include flexbox();
    justify-content: center;
    flex: 1;
    height: 750px;
    width: 100% !important;
    &.portrait-mode {
      .btn-expand {
        img {
          width: 18px;
        }
      }
      @media screen and (min-width: 991px) {
        background: #ffffff;
        box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
        // live chat
        .live__chat,
        .videoplayer__wrapper,
        .cta__wrapper {
          flex: 1;
          height: 100%;
        }
        .live__chat {
          background: #fff;
        }
        .cta__detailwrap {
          padding: 0;
          height: calc(100% - 100px);
        }
        .live__chat__header {
          padding: 20px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.3);
          h5 {
            font-weight: 600;
            font-size: 20px;
            line-height: 39px;
            color: #000000;
          }
        }
        .live-views {
          top: 15px;
          left: 25px;
        }
        .live__chat__body {
          height: calc(100% - 160px);
          .chat-message-msg {
            background: #f2f4fa;
            text-align: left;
            padding: 12px 15px;
            width: auto;
            max-width: 100%;
            .chat-avatar {
              color: #233ea4;
              background-color: transparent;
            }
            h4 {
              color: #000;
            }
          }
          .chat-message {
            &.sent {
              .chat-message-msg {
                background-color: rgba(114, 184, 88, 0.1);
                .chat-avatar {
                  color: #48af00;
                }
              }
            }
          }
        }
        .live__chat__footer {
          margin: 15px 20px;
          border-radius: 0px;
          height: 45px;
          display: flex;
          align-items: center;
          background: transparent;
          border: 1px solid #b7b7b7;
          form {
            width: 100%;
          }
          input {
            width: 100%;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            width: 100%;
            color: #000000;
            padding-right: 60px;
            &::placeholder {
              color: #000000;
            }
          }
          button {
            svg {
              path {
                fill: #848484;
              }
            }
          }
        }
        // cta
        .cta__cardwrap {
          padding: 0;
          margin: auto;
        }
      }
    }
    @media screen and(max-width: 991px) {
      height: 600px !important;
      &.portrait-mode {
        height: 80vh;
        background-color: #8b8b8b;
        .live__chat,
        .videoplayer__wrapper,
        .cta__wrapper {
          flex: auto;
        }
        .cta__cardwrap {
          width: calc(100% - 5px);
          margin: auto;
          margin-right: 0;
        }
        // .live__chat {
        //   display: none;
        // }
        .cta__detailwrap {
          height: 100%;
        }
        .videoplayer__wrapper {
          height: 100%;
        }
        @media screen and (orientation: landscape) {
          &.fullscreen-video {
            .live__chat {
              display: block !important;
              max-width: 245px;
              min-width: 245px;
              right: auto;
              left: 0;
              bottom: 53px;
              top: auto;
              z-index: 2;
              border-radius: 0px;
              height: calc(100vh - 135px);
              position: absolute;
              background: transparent;
              transition: opacity 0.5s ease-in-out !important;
              &.inactive {
                display: none !important;
              }
            }
            .live__chat__body {
              height: calc(100% - 0px);
            }
            .live__chat__footer {
              height: 45px;
              margin: 0 4px;
              input {
                color: #f1f4f9;
                &::placeholder {
                  color: #f1f4f9;
                }
              }
              button {
                top: 10px;
                svg {
                  path {
                    fill: #f1f4f9;
                  }
                }
              }
            }
          }
        }
      }
      @media screen and (orientation: landscape) {
        height: 90vh !important;
      }
    }
  }
  @media screen and (max-width: 991px) {
    &__container {
      padding: 0 0 1px;
    }
  }
}

.live-views {
  display: flex;
  position: absolute;
  top: 46px;
  left: 60px;

  .livetags {
    padding: 3px 8px;
    @include theme(background-color, themeColor);
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    text-transform: capitalize;
    &.live {
      animation-duration: 500ms;
      animation-name: blink;
      animation-iteration-count: infinite;
      animation-direction: alternate;
    }
  }
  h6 {
    background: #000000;
    color: #ffffff;
    font-size: 14px;
    line-height: 19px;
    padding: 3px 8px;
    display: inline-flex;
    align-items: center;
  }
  img {
    margin-right: 5px;
  }
  @media screen and (max-width: 991px) {
    .livetags,
    h6 {
      font-size: 12px;
    }
  }
}

@keyframes blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.btn-share-content {
  &.icons-share {
    position: absolute;
    background: #fff;
    padding: 5px 10px;
    border-radius: 12px;
    right: 0;
    opacity: 0;
    visibility: hidden;
    button {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
    &.isactive {
      opacity: 1;
      visibility: visible;
    }
  }
}
