$colors: (
  white: #ffffff,
  black: #000000,
  moderate-orange: #dfa243,
  dark-gray: #e6e6e6,
  white-smoke: #f4f4f4,
  sea-green: #18c595,
  light-green: #a4f0c4,
  nevy-blue: #005ff9,
  light-blue: #e0eeff,
  light-pink: #ffece4,
  blue: #4949c8,
  green: green,
);

body {
  --white: #ffffff;
  --black: #000000;
  --primary-bg-color: #dfa243;
  --primary-title-color: #dfa243;
  --primary-desc-color: #000;
  --dark-gray: #e6e6e6;
  --white-smoke: #f4f4f4;
  --sea-green: #18c595;
  --light-green: #a4f0c4;
  --nevy-blue: #005ff9;
  --light-blue: #e0eeff;
  --light-pink: #ffece4;
  --blue: #4949c8;
  --green: green;
  --tag-live-color: #dfa243;
  --tag-complete-color: #dfa243;
  --primary-font-size: 24px;
  --md-font-size: 16px;
  --sm-font-size: 14px;
  --xs-font-size: 12px;
}
