.cta {
  &__wrapper {
    width: 20%;
    position: relative;
    transition: all 0.3s linear;
    &.nocta {
      display: none;
    }
    .m-btn-minimize {
      position: absolute;
      right: 12px;
      top: 55px;
      transition: all 0.2s ease-in-out;
      @media screen and (max-width: 991px) {
        top: 10px;
        @media screen and (orientation: landscape) {
          top: 20px;
        }
      }
    }
    .cta__nocta {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.18);
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      font-size: 18px;
      color: #7b7b7b;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 50px;
      text-align: center;
      line-height: 140%;
      display: none;
    }
    &.portrait-mode {
      .cta__btngroup {
        flex-direction: row;
      }
      .cta__detailwrap {
        padding: 0 15px;
      }
      .cta__desc .btn {
        width: 90px;
        margin-top: 0px;
        @media screen and (max-width: 991px) {
          width: 100%;
        }
      }
      .cta__nocta {
        display: flex;
      }
    }
    @media screen and (max-width: 991px) {
      width: 100%;
      .cta__nocta {
        display: flex;
      }
      &.cta__close {
        width: 0px;
        min-width: 0;
        .m-btn-minimize {
          top: auto;
          bottom: 85px;
          right: -30px;
          transform: rotate(-180deg);
          z-index: 1;
        }
        .cta__detailwrap,
        .cta__nocta {
          opacity: 0;
          visibility: hidden;
        }
      }
      .btn__primary {
        background: linear-gradient(
          90deg,
          #0052d4 0%,
          #4364f7 50%,
          #6fb1fc 100%
        );
        position: absolute;
        left: 0;
        width: 100%;
        bottom: 0;
        img {
          margin-right: 5px;
        }
      }
      // &.portrait-mode {
      //   display: block !important;
      //   width: 100%;
      //   max-width: 100%;
      //   position: absolute;
      //   bottom: 0;
      //   background: #ffffff4f;
      //   height: 40vh !important;
      //   padding-top: 20px;
      // }
    }
  }
  &__detailwrap {
    height: 650px;
    padding-left: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 5px;
    &::-webkit-scrollbar {
      width: 3px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
      width: 1px;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      @include theme(background-color, themeColor);
      border-radius: 20px;
      width: 3px;
    }
    @media screen and (max-width: 991px) {
      height: 100%;
      padding-left: 5px !important;
      padding-right: 5px !important;
    }
  }
  &__card {
    background: #ffffff;
    border: 1px solid #f1f1f1;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 120px;
    border-radius: 5px;
    margin-bottom: 16px;
    position: relative;

    h4 {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #fff;
      text-align: center;
      @include theme(background-color, themeColor);
      border-radius: 4px 4px 0px 0px;
      padding: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    h5 {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #6d6d6d;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .btn {
      font-size: 12px;
      border-radius: 0px 0px 5px 5px;
    }

    @media screen and (max-width: 991px) {
      width: 180px;
      display: flex;
      padding: 2px;
      border: 0px;
      margin-bottom: 5px;
      h4 {
        min-width: 71px;
        min-height: 12px;
        background: #ebeaea;
        font-weight: 500;
        font-size: 10px;
        line-height: 14px;
        color: #0e0e0e;
        padding: 0 0 0 10px;
        text-align: left;
        margin: 5px 0;
        border-radius: 0px;
        display: inline-flex;
      }
      h5 {
        font-size: 12px;
      }
    }
  }
  &__wrap {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    margin-bottom: 16px;
    &:last-of-type {
      border-bottom: 0px solid transparent;
    }
    .cta__card {
      width: 100%;
      display: flex;
    }
    .cta__img {
      width: 92px;
      height: 92px;
      @media screen and (max-width: 991px) {
        width: 65px;
        height: 60px;
        background: transparent;
      }
    }
    .cta__desc {
      width: calc(100% - 92px);
      padding: 10px;
      h5 {
        color: #000000;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 5px;
        width: 90%;
      }
      h4 {
        background: #6fb756;
        display: inline-flex;
        min-width: 70px;
        height: 21px;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #fbfbfb;
        border-radius: 0px !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      p {
        margin-bottom: 14px;
      }
      .btn {
        width: 100%;
        height: 30px;
        margin-top: 5px;
        display: inline-flex;
        border-radius: 0px !important;
        background: linear-gradient(
          90deg,
          #0052d4 0%,
          #4364f7 50%,
          #6fb1fc 100%
        );
        img {
          border-right: 0.3px solid #ffffff;
          padding-right: 8px;
          margin-right: 10px;
        }
      }
      @media screen and (max-width: 991px) {
        width: calc(100% - 65px);
        padding: 10px 5px;
        .btn {
          width: 100%;
          height: 25px;
          border-radius: 0 0 5px 5px !important;
        }
        p {
          margin-bottom: 3px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        h5 {
          margin-bottom: 3px;
          width: 100%;
        }
        h4 {
          margin-bottom: 15px;
          font-size: 12px;
          padding-left: 0;
          height: 20px;
          margin-top: 0;
        }
      }
    }
    @media screen and (max-width: 991px) {
      margin-bottom: 5px;
    }
  }
  &__btngroup {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: column;
    @media screen and (max-width: 991px) {
      align-items: flex-start;
    }
  }
  &__mobile {
    @media screen and (max-width: 991px) {
      height: 100%;
    }
  }
  &__img {
    width: calc(100% - 4px);
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px;

    img {
      width: auto;
      height: 114px;
      max-width: 100%;
      border-radius: 10px;
      object-fit: contain;
    }

    @media screen and (max-width: 991px) {
      width: 64px;
      height: 64px;
      background: #d8d8d8;
      border-radius: 5px;
      z-index: 1;
      margin: 2px;
      img {
        height: 64px;
      }
    }
  }
  &__desc {
    @media screen and (max-width: 991px) {
      width: calc(100% - 64px);
      padding: 5px 10px 0;
    }
  }
  &__heading {
    padding: 26px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    margin-bottom: 15px;
    h4 {
      font-weight: 600;
      font-size: 16px;
      line-height: 27px;
      color: #000000;
      margin-bottom: 0;
    }
    @media screen and (max-width: 991px) {
      display: none;
    }
  }
  // mobile show
  &__mobileshow {
    position: absolute;
    bottom: 60px;
    right: 10px;
    display: none;
    .cta__cardwrap,
    .cta__wrap,
    .cta__card {
      width: 55px;
    }
    .cta__card {
      background: #fff;
      box-shadow: none;
      position: relative;
      display: flex;
      flex-direction: column;
      text-align: center;
      img {
        width: 38px;
        margin: auto;
      }
    }
    .cta__wrap {
      border-bottom: 0px;
    }
    .cta__img {
      width: 100%;
      height: auto;
      cursor: pointer;
      img {
        width: 98%;
        height: auto;
        border-radius: 2px;
        max-height: 100px;
      }
    }
    .seemore-btn {
      display: none;
    }
    @media screen and (max-width: 991px) {
      display: block;
      .seemore-btn {
        position: relative;
        background: #fff;
        padding: 2px 2px 4px;
        z-index: 1;
        bottom: 0px;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 8px;
        font-weight: 600;
        display: block;
        color: #d91c5c;
      }
    }
  }
}

.ctamodal {
  &__wrapper {
    position: fixed;
    z-index: 1;
    background: rgba(0, 0, 0, 0.75);
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;

    h5 {
      font-weight: bold;
      font-size: 18px;
      line-height: 140%;
      color: #000000;
      margin-bottom: 20px;
      padding-right: 50px;
      text-transform: uppercase;
    }
  }
  &__form {
    width: 400px;
    box-shadow: 0px 0.531532px 2.12613px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 20px;
    background: rgba(255, 255, 255, 1);
    backdrop-filter: saturate(180%) blur(10px);
    &.download {
      width: 240px;
    }
    &.success {
      h6 {
        color: #72b92e;
        font-size: 20px;
        margin: 20px 0 10px;
        text-align: center;
        font-weight: bold;
      }
      p {
        font-size: 16px;
        text-align: center;
        margin-bottom: 10px;
      }
      img {
        width: 200px;
        margin: auto;
      }
    }
    .form-group {
      margin-bottom: 30px;
    }
    input:not([type="checkbox"]):not([type="radio"]) {
      width: 100%;
      height: 45px;
      border: 1px solid #414141;
      border-radius: 4px;
      padding: 10px 15px;
      background: transparent;
      color: #000;
      font-weight: bold;
      font-size: 14px;
      outline: none;
      box-shadow: none;
      &:focus {
        border-color: #205be5;
      }
      &.error {
        border-color: #ac4742;
      }
    }
    .poll-percentage {
      position: absolute;
      right: 10px;
      z-index: 1;
    }
    .radio-btn {
      width: 100%;
      height: 45px;
      border: 1px solid #414141;
      border-radius: 4px;
      background: transparent;
      display: flex;
      align-items: center;
      position: relative;
      span {
        font-size: 14px;
        height: 100%;
        color: #000;
        padding: 10px 15px;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        border-radius: 3px;
        transition: width 0.5s ease-in-out;
        &::before {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          z-index: -1;
        }
      }
      input {
        height: 0px;
        width: 0px;
        opacity: 0;
        &:checked + span {
          &::before {
            box-shadow: rgba(0, 82, 212, 0.65) 0px 0px 6px 2px;
          }
        }
      }
      &.partially {
        border-color: #ac4742;
        span {
          &::before {
            box-shadow: rgba(172, 71, 66, 0.65) 0px 0px 6px 2px;
          }
        }
      }
      &.false {
        border-color: #ac4742;
        background: #ac4742;

        span {
          color: #fff;
          &::before {
            box-shadow: rgba(172, 71, 66, 0.65) 0px 0px 6px 2px;
          }
        }
      }
      &.success {
        border-color: #47c790;
        background: #3ba779;

        span {
          color: #fff;
          &::before {
            box-shadow: rgba(26, 158, 102, 0.65) 0px 0px 6px 2px;
          }
        }
      }
    }
    .btn {
      width: 100%;
      height: 45px;
      border-radius: 5px;
      font-weight: bold;
      font-size: 16px;
      line-height: 16px;
      color: #ffffff;
    }
    .btn-submit {
      background: #000000;
      margin-top: 30px;
    }
    @media screen and (max-width: 520px) {
      width: 90%;
    }
  }
  &__downloads {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #c0c0c0;
    &:last-child {
      border-bottom: 0px;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    h4 {
      font-weight: bold;
      font-size: 12px;
      line-height: 140%;
      color: #374957;
      margin-bottom: 10px;
    }
    .btn-download {
      background: #00af80;
    }
  }
}
.modalclosebtn {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 2em;
  height: 2em;
  cursor: pointer;
  border: none;
  background-color: transparent;
  z-index: 99;
}
.modalclosebtn::after,
.modalclosebtn::before {
  position: absolute;
  top: calc(50% - 2px);
  left: 0.15em;
  content: "";
  width: 2em;
  height: 2px;
  background-color: #000;
  transform-origin: center center;
  transition: 0.5s;
  border-radius: 1em;
}
.modalclosebtn::before {
  transform: rotate(-45deg);
}
.modalclosebtn::after {
  transform: rotate(45deg);
}
.modalclosebtn:hover::after,
.modalclosebtn:hover::before {
  transform: rotate(-180deg);
}
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .leadcta__form {
    -webkit-backdrop-filter: saturate(180%) blur(10px);
    backdrop-filter: saturate(180%) blur(10px);
  }
}

.error-msg {
  margin: 4px 0;
  color: #ac4742 !important;
  display: block;
  font-weight: 600;
  position: absolute;
}

.successScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
